.row {
    display: flex;
    align-items: center;
    height: 100%;
    overflow: hidden;
}

.col {
    width: 50%;
    background-color: white;
    height: 561px;
    overflow: hidden;
}

.img-bg {
    background-size: cover;
    max-width: 40rem;
    height: 559px;

    img {
        width: 100%;
    }
}

.content-form {
    padding: 20% 15%;
    max-width: 28rem;
    box-sizing: unset !important;
    background-color: white;
}

.logo-sei {
    width: 4rem;
}

.content-form p {
    font-size: 1.300rem;
    color: #CCCCCC;
}

.content-form h4 {
    font-size: 1.1rem;
    margin-bottom: .25rem;
    color: #666666;
    font-weight: normal;
}

.formContainer {
    height: auto!important;
    margin-top: 0.5em;
    margin-bottom: 3rem;
}

.formContainer .ui-alert {
    margin: 10px 0;
    border-color: rgb(243, 214, 216);
    background-color: rgb(252, 244, 246);
    color: rgb(196, 49, 75);
}


.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label,
.form-group span.ui-text,
.form-group-dd label {
    font-size: 1.1rem;
    margin-bottom: .25rem;
    color: #666666;
}

.form-group span.ui-text {
    font-style: italic;
    margin:4px 0;
}

.form-group span.link {
    font-style: italic;
    font-family: "Segoe UI", system-ui, "Apple Color Emoji", "Segoe UI Emoji", sans-serif;
    font-size: 11px;
    color: #008BD2;
    margin-top: -6px;
    cursor: pointer;
    text-decoration: underline;
}

.form-group input[type='text'],
.form-group input[type='password'],
.form-group select,
.form-group-dd button, .ui-dropdown__selected-items {
    padding: .75rem;
    border-radius: .25rem;
    border: 1px solid #DDDDDD;
    font-family: 'Ubuntu', Segoe UI, sans-serif;
    background-color: white;
    color: rgb(37, 36, 35);
    width: 100%;
}

.form-group-dd .ui-dropdown__selected-items {
    padding:2px 10px;
}

.form-group-dd ul.jy {
    background-color: white;
    color: rgb(37, 36, 35);
    border-radius: .25rem;
    border: 1px solid #DDDDDD;
}

.form-group-dd .ui-button__content {
    font-weight: normal;
}

.form-group-dd button {
    border: none;
    padding: 0;
}

.form-group .sign-in-button {
    border: none;
    padding: 0;
    overflow: visible;
    display: inline-flex;
}

.form-group .sign-in-button:disabled {
    background-color: #e6eac4;
}

.ui-loader {
    height: unset !important;
    margin-right: 0.5857rem!important;
}

.form-group .ui-loader__indicator {
    height: 1.9286rem !important;
}

    .u ::-webkit-scrollbar-thumb {
        background-color: #c8c6c4;
    }

    .form-group {
        margin-bottom: .75rem;
    }

    .login-btn button {
        background: #A5B61B;
        color: #FFFFFF!important;
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
        border: none;
        border-radius: 30rem;
        padding: .75rem;
        font-family: 'Ubuntu', Segoe UI, sans-serif;
        cursor: pointer;
    }

    .login-btn {
        margin-top: 1.4rem;
    }

    ::placeholder {
        color: #CCCCCC;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: #CCCCCC;
    }


.content-form-help {
    padding: 20% 15%;
    max-width: 28rem;
    box-sizing: unset !important;
    background-color: white;
}

.content-form-help img {
    height:40px;
    float:left;
    margin-left:5rem;
}

.content-form-help p {
    font-size: 1.300rem;
    color: #CCCCCC;
}

.content-form-help h3 {
    color: #666666;
    font-weight: normal;
    margin-top: 1rem;
}

.content-form-help h4 {
    font-size: 1.1rem;
    margin-bottom: .45rem;
    color: #666666;
    font-weight: normal;
}

.form-group textarea, .form-group textarea:focus {
    padding: .75rem;
    border-radius: .25rem;
    border: 1px solid #DDDDDD;
    font-family: 'Ubuntu', Segoe UI, sans-serif;
    background-color: white;
    color: rgb(37, 36, 35);
    width: 100%;
    min-height: 12rem;
}

.form-group-dd {
    margin-bottom: .75rem;
}

.content-form-help .form-group-dd .ui-button__content {
    color: #CCCCCC;
}

.content-form-help .buttons button,
.ui-dialog button {
    width: 55%;
    float: left;
    background: #A5B61B;
    color: #FFFFFF!important;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    border: none;
    border-radius: 30rem;
    padding: .75rem;
    font-family: 'Ubuntu', Segoe UI, sans-serif;
    cursor: pointer;
}

.ui-dialog {
    background: rgb(255, 255, 255) !important;
    color: rgb(37, 36, 35) !important;
}

.documentView {
    padding: 0 !important;
    margin-bottom: 2rem;

    .header {
        background-color: #f5f5f5;
        padding: 2rem;
        position: fixed;
        height: 7rem;
        top: 0;

        .documentInfo {
            padding-left: 1rem;
            padding-top: 0.3rem;
            font-size: 1.6rem;
        }
    }

    .mainDocument {
        margin-top: 10rem;

        #divContent {
            padding: 6rem;
            border: 1px rgba(34, 36, 38, 0.15) solid;
            border-radius: 4px;
        }
    }
}

.errorMessage {
    margin: 20% 0;
}

.signPanel {
    width: 33.2rem;

    .ui-dropdown__container, .ui-list {
        max-width: 30rem;
    }

    .buttons {
        position: absolute;
        bottom: 8rem;
        right: 2rem;

        .ui-button {
            margin-left: 1rem;
        }
    }
}

/* width */
::-webkit-scrollbar {
    width: 0.6rem;
}

::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
    width: 0.6rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    ::-webkit-scrollbar {
        width: 0.7rem !important;
    }
}
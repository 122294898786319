.wopi-document-container {
    padding: 0rem 4rem;

    h2 span {
        font-size: 1.8rem;
    }

    .ui-header__description {
        font-size: 1.5rem;
    }

    iframe {
        width: 100rem;
        height: 65rem;
        min-width: 98%;
        min-height: 40rem;
        padding-bottom: 2rem;
    }
}

@media only screen and (min-width : 226px) and (max-width : 400px) {
    iframe {
        min-width: 35rem !important;
        max-width: 35rem !important;
    }
}
